import React from 'react';
import HomePage from './HomePage'; // Make sure the 'P' is uppercase

function App() {
  return (
    <div className="App">
      <HomePage />
    </div>
  );
}

export default App;
