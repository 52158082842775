import { Configuration, OpenAIApi } from 'openai';

const configuration = new Configuration({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
});

const openai = new OpenAIApi(configuration);

export async function getResponse(prompt) {
  try {
    const response = await openai.createChatCompletion({
      model: 'gpt-3.5-turbo',
      messages: [
        {
          role: 'system',
          content:
            'You are an advice giver, people come to you when they cant decide and they come and ask you, you give them a straight and simple answer and never saying \'it depends\' and if the user asks for more info you give them the extra info',
        },
        { role: 'user', content: prompt },
      ],
      temperature: 0.7,
      max_tokens: 100,
    });

    const responsetext = response.data.choices[0].message.content;
    console.log(responsetext);
    if (response.data.choices && response.data.choices.length > 0) {
      return response.data.choices[0].message.content;
    } else {
      return 'No response received.';
    }
  } catch (error) {
    console.error('Error getting response from OpenAI API:', error);
    return 'Error getting response.';
  }
}
