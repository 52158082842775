import React from 'react';
import './HomePage.css';
import ChatBox from './ChatBox';

const HomePage = () => {
  return (
    <div className="container">
      <div className="header">
        <h1>Decider</h1>
        <p>
          decesion anixiety? let chatgpt decide for you! <br />
        </p>
      </div>
      <div className="container">
      {/* ... */}
      <ChatBox />
      {/* ... */}
      </div>
    </div>
  );
};

export default HomePage;
