import React, { useState } from 'react';
import './ChatBox.css';
import { getResponse } from './api';

const ChatBox = () => {
  const [inputText, setInputText] = useState('');
  const [responseText, setResponseText] = useState('');

  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputText.trim() === '') {
      return;
    }

    try {
      const responseText = await getResponse(inputText);
      setResponseText(responseText.trim());
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  return (
    <div className="chat-box-container">
      <div className="response-container">
        <p>{responseText}</p>
      </div>
      <form className="input-form" onSubmit={handleSubmit}>
        <textarea
          className="input-text"
          value={inputText}
          onChange={handleInputChange}
          placeholder="Should I touch grass or should I sit back?"
        />
        <button type="submit">Send</button>
      </form>
    </div>
  );
};

export default ChatBox;
